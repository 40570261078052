<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Krankheiten bei Rindern</h1>

      <h2>Rindertuberkulose</h2>
      <figure>
        <img src="@/assets/images/tbc-bfr.jpg" />
        <figcaption>Quelle: <a
            href="https://www.bfr.bund.de/de/fragen_und_antworten_zur_rindertuberkulose-132506.html">Bundesinstitut für
            Risikobewertung</a></figcaption>
      </figure>
    </div>
  </div>
</template>


<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  components: { Sidebar }
}
</script>
